import { updateBreadcrumbs } from "@gokwik/utilities";
import { Form, Switch } from "gokwik-ui-kit";
import React, { useEffect } from "react";
import { CustomSwitch, SectionHeader } from "..";

const LoginMobile = ({data, handleConfigChange}) => {
  useEffect(() => {}, []);

  return (
    <div className="p-6 pt-0">
      <SectionHeader
        title="Login/Mobile"
        description="Manage the configurations which is used for authenticating a user"
      />
      <div className="w-1/2 pt-8">
        <div className="text-sm mb-5">
          <div className="font-bold">User Verification Method</div>
          <div className="text-gray-400 mb-5">
            {/* Some text for this subsection */}
          </div>
          <Form>
            <CustomSwitch
              title="Mobile & OTP"
              description="Users will be able to authenticate via OTP which will be sent to the entered mobile number"
              value={data.mobile_otp}
              switch_key={["login_mobile","mobile_otp"]}
              handleConfigChange={handleConfigChange}
              isDisabled={true}
            />
            <CustomSwitch
              title="Truecaller verification"
              description="Users can authenticate via Truecaller App with just a click "
              value={data.truecaller_verification}
              switch_key={["login_mobile","truecaller_verification"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="WhatsApp (OTP Less)"
              description="Users can authenticate via WhatsApp"
              value={data.whatsapp_otpless}
              switch_key={["login_mobile","whatsapp_otpless"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
          </Form>
        </div>
        <div className="text-sm mb-5">
          <div className="font-bold">OTP Settings</div>
          <div className="text-gray-400 mb-5">
            Manage the authentication for the users
          </div>
          <Form colon={false}>
            {/* <Switch checked={data.enable_otp_for_gk_users} onChange={()=>handleConfigChange('login_mobile','enable_otp_for_gk_users', !data.enable_otp_for_gk_users)} /> */}
            <CustomSwitch
              title="Enable OTP for GoKwik New users"
              description="Enabling this option will ask for a OTP (as a verification) for GoKwik New users. i.e. A user who is checking out via GoKwik for the very first time."
              value={data.enable_otp_for_gk_users} 
              switch_key={["login_mobile","enable_otp_for_gk_users"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
             
            />
            <CustomSwitch
              title="Skip OTP flow"
              description="This will skip the OTP authentication which is not recommended. Please enable this consciously"
              value={data.skip_otp_flow}
              switch_key={["login_mobile","skip_otp_flow"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="Send OTP on WhatsApp"
              description="GoKwik sends the OTP on users WhatsApp. In case if user is connected to WiFi/Hotspot and does not have a network, OTP gets delivered."
              value={data.sent_otp_on_whatsapp}
              switch_key={["login_mobile","sent_otp_on_whatsapp"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginMobile
