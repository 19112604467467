import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getMerchantDetails, getMode, getUserDetails } from '@store/user/selectors';

import { isMerchantSelector } from '@store/user/selectors';
import Settings from '@pages/settings';

const AppRoutes = () => {
  const userDetails = useSelector(getUserDetails);
  const merchantDetails = useSelector(getMerchantDetails);
  const isMerchantUser = useSelector(isMerchantSelector);
  const mode = useSelector(getMode);
  if (!Object.keys(userDetails || {}).length) return <></>;
  return (
    <Routes key={`${mode}-${JSON.stringify(merchantDetails)}`}>
      <Route path='/checkout-platform/settings/*' Component={Settings} />
    </Routes>
  );
};

export default AppRoutes;
