import { makeAPICall, updateBreadcrumbs } from "@gokwik/utilities";
import {
  BgColorsOutlined,
  Button,
  EditOutlined,
  Form,
  Input,
  PoweroffOutlined,
  Radio,
  Upload,
  UploadFile,
  UploadOutlined,
  message,
} from "gokwik-ui-kit";
import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { SectionHeader } from "..";
import styled from "styled-components";

function debounce(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;
  return function (...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
export const CustomColorPicker = ({ value, form_key, handleConfigChange }) => {
  const [color, setColor] = useState("#005cb3");

  useEffect(() => {
    if (value) setColor(value);
  }, [value]);

  const debouncedHandleConfigChange = useCallback(
    debounce((formKey, colorValue) => {
      handleConfigChange("personalization", formKey, colorValue);
    }, 300),
    []
  );

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setColor(newColor);
    debouncedHandleConfigChange(form_key, newColor);
  };

  return (
    <div className="flex items-center gap-2">
      <div
        className="w-8 h-8 border border-double border-gray-400 rounded"
        style={{ backgroundColor: color }}
      ></div>
      <div className="relative w-36">
        <Input
          value={color}
          readOnly
          style={{ textAlign: "center" }}
          suffix={
            <label style={{ cursor: "pointer" }} htmlFor="color-picker">
              <EditOutlined />
            </label>
          }
        />
        <input
          id="color-picker"
          type="color"
          value={color}
          onChange={handleColorChange}
          className="absolute top-0 right-0 opacity-0 w-full h-full cursor-pointer"
        />
      </div>
    </div>
  );
};

const Personalization = ({ data, handleConfigChange, merchantDetails }) => {
  const [logoUrl, setLogoUrl] = useState(data.logo || "");
  const [fileList, setFileList] = useState<UploadFile[]>(
    data.logo
      ? [
          {
            uid: "-1",
            name: "logo.png",
            status: "done",
            url: data.logo,
          },
        ]
      : []
  );

  const getSignedUrl = async (path: string) => {
    try {
      const response = await makeAPICall({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/dashboard/utility/presigned-url`,
        payload: {
          path: path,
          type: "logo",
        },
      });

      if (response.success) {
        return response.data.data.url;
      } else {
        throw new Error("Failed to get signed URL");
      }
    } catch (error) {
      message.error("Error generating signed URL");
      console.error(error);
      return null;
    }
  };

  const getDownloadUrl = async (path: string) => {
    try {
      const response = await makeAPICall({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/dashboard/utility/download`,
        payload: {
          path: path,
          type: "logo",
        },
      });

      if (response.success) {
        return response.data.data.download_url;
      } else {
        throw new Error("Failed to get download URL");
      }
    } catch (error) {
      message.error("Error generating download URL");
      console.error(error);
      return null;
    }
  };

  const handleBeforeUpload = (file: UploadFile) => {
    // setFileList([
    //   {
    //     uid: file.uid,
    //     name: file.name,
    //     status: "uploading",
    //   },
    // ]);
    return true;
  };
  function extractFilePath(url) {
    try {
      // Create a URL object
      const parsedUrl = new URL(url);

      // Extract the pathname (everything after the domain)
      const path = parsedUrl.pathname;

      // Remove the leading slash from the pathname
      return path.startsWith("/")
        ? "https://" + path.slice(1)
        : "https://" + path;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }
  const handleUploadChange = async (
    info: any,
    event?: React.SyntheticEvent
  ) => {
    if (event) event.preventDefault();
    const file = info.file;

    if (file.status !== "uploading") return;

    try {
      const timestamp = Date.now();
      const fileExtension = file.name.split(".").pop();
      const path = `merchant/${merchantDetails.m_id}/logo${timestamp}.${fileExtension}`;

      // Get signed URL
      const signedUrl = await getSignedUrl(path);
      if (!signedUrl) throw new Error("Failed to generate signed URL");

      // Upload file to AWS S3
      const awsResponse = await fetch(signedUrl, {
        method: "PUT",
        body: file.originFileObj,
      });
      if (!awsResponse.ok) throw new Error("AWS upload failed");

      // Get public URL
      const publicUrl = await getDownloadUrl(path);
      if (!publicUrl) throw new Error("Failed to generate public URL");
      message.success("Logo uploaded successfully");
      setLogoUrl(extractFilePath(publicUrl));
      handleConfigChange("personalization", "logo", extractFilePath(publicUrl));
      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: extractFilePath(publicUrl),
        },
      ]);
    } catch (error) {
      message.error("Logo upload failed");
      setFileList([]);
      console.error(error);
    }
  };

  const handleRemove = () => {
    setLogoUrl("");
    setFileList([]);
  };
  //const [logoType, setLogoType] = useState("upload")
  const inputRef = useRef();
 
 
  const handlelogoRadioChange = (e) => {
  handleConfigChange("personalization", "logoType", e.target.value);
 
  
  };
  const debouncedHandleConfigChange = useMemo(
    () =>
      debounce((value) => {
        handleConfigChange("personalization", "logo", value);
      }, 1000),
    [handleConfigChange]
  );


  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setLogoUrl(newValue); // Update the local state first
    debouncedHandleConfigChange(newValue); 
  };
 
  return (

    <div className="p-6 pt-0">
      <SectionHeader
        title="Personalisation"
        description="Personalise the checkout UI based on your brand color guidelines."
      />
      <div className="w-1/2 pt-5">
        <div className="text-sm mb-5">
          <div className="font-bold">Logo</div>
          <div className="text-gray-400 mb-5">
            Upload your brand logo or input the logo url which is to be
            displayed on the Checkout
          </div>

          <Form layout="vertical">
            <Form.Item>
              <Radio.Group
                onChange={handlelogoRadioChange}
                value={data.logoType}
              >
                <Radio value={"upload"}>Upload your brand logo</Radio>
                <Radio value={"input"}>Enter logo URL</Radio>
              </Radio.Group>
              <br />
              {data.logoType == "upload" ? (
                <div className="upload-outer">
                <Upload
                  listType="picture"
                  fileList={fileList}
                  // beforeUpload={handleBeforeUpload}
                  onChange={handleUploadChange}
                  onRemove={handleRemove}
                  maxCount={1}
                  multiple={false}
                  accept=".jpeg,.png,.jpg"
                >
                  <Button icon={<UploadOutlined />} className="mt-10">
                    Click to Upload
                  </Button>
                </Upload>
                </div>
              ) : (
                <Input
                className="mt-10"
                value={logoUrl}
                ref={inputRef}
                onChange={handleInputChange}
                placeholder="Enter logo url"
              />
              )}
            </Form.Item>
          </Form>
        </div>
        <div className="text-sm mb-5">
          <div className="font-bold">Checkout Theme</div>
          <div className="text-gray-400 mb-5">
            Define your colour themes you would like to use.
            <br />
            Note: GoKwik has mapped the components based on primary, secondary
            and tertiary color styling.
          </div>
          <Form layout="vertical">
            <Form.Item
              label="Primary Theme color (Background)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your primary background color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.primary_bg_color}
                form_key="primary_bg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item>
            <Form.Item
              label="Primary Theme color (Foreground)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your primary foreground color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.primary_fg_color}
                form_key="primary_fg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item>
            <Form.Item
              label="Secondary Theme color (Background)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your secondary background color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.secondary_bg_color}
                form_key="secondary_bg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item>
            <Form.Item
              label="Secondary Theme color (Foreground)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your secondary foreground color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.secondary_fg_color}
                form_key="secondary_fg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item>
            {/* <Form.Item
              label="Tertiary Theme color (Background)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your tertiary background color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.tertiary_bg_color}
                form_key="tertiary_bg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item>
            <Form.Item
              label="Tertiary Theme color (Foreground)"
              className="inline-block text-sm m-2"
            >
              <div className="text-gray-400 mb-5 text-xs">
                Set your tertiary foreground color which is mapped across the
                CTAs buttons
              </div>
              <CustomColorPicker
                value={data?.tertiary_fg_color}
                form_key="tertiary_fg_color"
                handleConfigChange={handleConfigChange}
              />
            </Form.Item> */}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Personalization;
