import { configureStore } from '@reduxjs/toolkit';
import filters from './filters';
import user from './user';

const store = configureStore({
  reducer: {
    filters,
    user,
  },
  devTools: true,
});

export default store;
export type RootStoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
