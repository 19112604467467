import { updateBreadcrumbs } from "@gokwik/utilities";
import { Form, Input, Switch } from "gokwik-ui-kit";
import { useEffect } from "react";
import { CustomSwitch, SectionHeader } from "..";

const Offers = ({data, handleConfigChange}) => {
  useEffect(() => {}, []);

  return (
    <div className="p-6 pt-0">
      <SectionHeader
        title="Offers & Benefits"
        description="Checkout offers are powered by the merchants/platform."
      />
      <div className="w-1/2 pt-8">
        <div className="text-sm mb-5">
          <Form colon={false}>
            <CustomSwitch
              title="Enable apply discount UI"
              description="Enable this if you do not want the users to apply/remove any coupons during the checkout journey"
              value={data.is_gk_discount_enabled}
              switch_key={["offers","is_gk_discount_enabled"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Offers;
