import { updateBreadcrumbs } from "@gokwik/utilities";
import {
  Button,
  Checkbox,
  DeleteOutlined,
  EditOutlined,
  Form,
  Input,
  Select,
  Switch,
  Table,
} from "gokwik-ui-kit";
import { useEffect, useState } from "react";
import { CustomSwitch, SectionHeader } from "..";

const Generic = ({ data, handleConfigChange }) => {
  const allowGiftcardOptions = [
    { value: "DISABLED", label: "Disabled" },
    { value: "CUSTOM", label: "Custom" },
    { value: "QWIKCILVER", label: "Qwikcilver" },
  ];
  const [customText, setCustomText] = useState("");
  const [coloumnData, setColumnData] = useState([]);
  useEffect(() => {
    if (data.custom_banner_messages.length > 0) {
      let arr = [];
      data.custom_banner_messages.forEach((element) => {
        let obj = { key: arr.length + 1, custom_text: element };
        arr.push(obj);
      });
      setColumnData(arr);
    }
  }, [data]);
  const addCustomData = () => {
    if (customText) {
      const newEntry = {
        key: coloumnData.length + 1,
        custom_text: customText,
      };
      setColumnData([...coloumnData, newEntry]);
      handleConfigChange(
        "generic",
        "custom_banner_messages",
        [...coloumnData, newEntry].map((item) => item.custom_text)
      );
      setCustomText("");
    }
  };
  const editCustomData = (key) => {
    const itemToEdit = coloumnData.find((item) => item.key === key);
    if (itemToEdit) {
      setCustomText(itemToEdit.custom_text);
      const updatedColumnData = coloumnData.filter((item) => item.key !== key);
      setColumnData(updatedColumnData);
    }
  };

  const deleteCustomData = (key) => {
    const updatedColumnData = coloumnData.filter((item) => item.key !== key);
    setColumnData(updatedColumnData);
    handleConfigChange(
      "generic",
      "custom_banner_messages",
      updatedColumnData.map((item) => item.custom_text)
    );
  };

  const columns = [
    {
      title: "Custom Text Message",
      dataIndex: "custom_text",
      width: "65%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "delete",
      width: "35%",
      render: (_, record) => (
        <div className="flex">
          <Button
            className="border-none"
            onClick={() => editCustomData(record.key)}
          >
            <EditOutlined />
          </Button>
          <Button
            className="border-none"
            onClick={() => deleteCustomData(record.key)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div className="p-6 pt-0">
      <SectionHeader
        title="Generic"
        description=""
      />
      <div className="w-1/2 pt-5">
        <div className="text-sm mb-5">
          <Form layout="vertical">
            <Form.Item
              label="Allow users to add Giftcard"
              className="inline-block text-sm w-80"
            >
              <div className="text-gray-400 text-xs">
                User shall be able to enter the gift card and use them during
                the checkout.
              </div>
              <Select
                options={allowGiftcardOptions}
                value={data.allow_giftcard}
                onChange={(e) =>
                  handleConfigChange("generic", "allow_giftcard", e)
                }
                className="mt-2 w-40"
              />
            </Form.Item>
            <CustomSwitch
              title="Exit feedback Questionnaire format"
              description="Enable the exit action with a set of questions to understand their intent. This data can be used to improve the shopping experience"
              value={data.exit_feedback}
              switch_key={["generic", "exit_feedback"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="Allow users to add GST"
              description="Incase if you would want to allow users to enter their GST number while purchasing, you can enable this field. This data will get passed to the backend platform along with the order creation. "
              value={data.allow_users_gst}
              switch_key={["generic", "allow_users_gst"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="Microsoft (MS) Clarity "
              description="You'll be able to record the user journey. This helps to understand any user pattern (or) debug if any specific issue. IMPORTANT: Do NOT enable it without conscent from the product. This may slow down. If you are enabling, do it for a shorter time duration. "
              value={data.ms_clarity}
              switch_key={["generic", "ms_clarity"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
          </Form>
          <div className="font-bold">Banner Customization</div>
          <div className="text-gray-400 mb-5">
            Any important message you would like your users to see during the
            cheeckout can be configured here.
          </div>
          <Form layout="vertical">
            <CustomSwitch
              title="Custom banner"
              description="Customize the banner with any text you want the users to see during the checkout."
              value={data.custom_banner}
              switch_key={["generic", "custom_banner"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            {data.custom_banner && (
              <>
                <Form.Item
                  label="Custom banner message (1,2,3)"
                  className="inline-block text-sm w-72"
                >
                  <div className="text-gray-400 text-xs">
                    Add the banner text here
                  </div>
                  <Input
                    className="mt-2 w-72"
                    value={customText}
                    onChange={(e) => setCustomText(e.target.value)}
                    placeholder="Add the banner text here"
                  />
                </Form.Item>

                <Button
                  type="primary"
                  className="mt-[3.2rem] ml-2"
                  onClick={addCustomData}
                >
                  Add
                </Button>
                <Table columns={columns} dataSource={coloumnData} />
              </>
            )}
          </Form>
        </div>
        {/* <div className="text-sm mb-5">
          <div className="font-bold">Checkout Events Tracking</div>
          <div className="text-gray-400 mb-5">
            some desc about this subsection
          </div>
          <div className="font-bold">FB Pixel</div>
          <Form layout="vertical">
            <CustomSwitch
              title="Enable FB Events"
              description="some description to add"
              value={true}
              onChange={() => {}}
            />
            <Form.Item>
              <Checkbox>List of FB Events</Checkbox>
            </Form.Item>
            <Form.Item label="Pixel ID" className="inline-block text-sm">
              <div className="text-gray-400 text-xs">
                Some description to add
              </div>
              <Input className="mt-2 w-72" />
            </Form.Item>
            <Form.Item
              label="Conversion API Token"
              className="inline-block text-sm"
            >
              <div className="text-gray-400 text-xs">
                Some description to add
              </div>
              <Input className="mt-2 w-72" />
            </Form.Item>
          </Form>
          <div className="font-bold">GA Events</div>
          <Form layout="vertical">
            <CustomSwitch
              title="Enable GA Events"
              description="some description to add"
              value={true}
              onChange={() => {}}
            />
            <Form.Item>
              <Checkbox>List of GA Events</Checkbox>
            </Form.Item>
            <Form.Item label="GA ID" className="inline-block text-sm">
              <div className="text-gray-400 text-xs">
                Some description to add
              </div>
              <Input className="mt-2 w-72" />
            </Form.Item>
            <CustomSwitch
              title="Login re-direction (cookieless handling)"
              description="some description to add"
              value={true}
              onChange={() => {}}
            />
            <Form.Item
              label="Cart re-direction URL"
              className="inline-block text-sm"
            >
              <div className="text-gray-400 text-xs">
                Some description to add
              </div>
              <Input className="mt-2 w-72" />
            </Form.Item>
          </Form>
        </div> */}
      </div>
    </div>
  );
};

export default Generic;
