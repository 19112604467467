import { updateBreadcrumbs } from "@gokwik/utilities";
import { Form, Input, Select, Switch } from "gokwik-ui-kit";
import { useEffect } from "react";
import { CustomSwitch, SectionHeader } from "..";

const Workflows = ({data, handleConfigChange}) => {
  useEffect(() => {}, []);
 
  const skipPaymentOptions = [
    {value:"DISABLED",label:"Disabled"},
    {value:"ONE_ADDRESS",label:"One Address"},
    {value:"MULTIPLE_ADDRESS",label:"Multiple Address"}
  ]
  
  const codConfirmationOptions = [
    {value:"ALWAYS",label:"Always"},
    {value:"NEW_USER",label:"New User"},
    {value:"NEVER",label:"Never"},
    {value:"PROMPT",label:"Prompt"}
  ]
  return (
    <div className="p-6 pt-0">
      <SectionHeader
        title="Workflows"
        description="GoKwik has powered the checkout with a few workflows from an convernience lever which can boost the conversions. "
      />
      <div className="w-1/2 pt-5">
        <div className="text-sm mb-5">
          <Form layout="vertical">
            <Form.Item
              label="Skip to Payments"
              className="inline-block text-sm w-80"
            >
              <div className="text-gray-400 text-xs">
                Allows the GoKwik repeat users to direcltly land on the payments
                page after entering the mobile number with preselected address
              </div>
              <Select
                options={skipPaymentOptions}
                value={data.skip_to_payments}
                onChange={(e) =>
                  handleConfigChange("workflows", "skip_to_payments", e)
                }
                className="mt-2 w-40"
              />
            </Form.Item>
            <br />
            <Form.Item
              label="COD confirmation mode"
              className="inline-block text-sm w-80"
            >
              <div className="text-gray-400 text-xs">
              This enables a confirmation for all users via a prompt message on COD payment option.
              </div>
              <Select
                options={codConfirmationOptions}
                value={data.cod_confirmation}
                onChange={(e) =>
                  handleConfigChange("workflows", "cod_confirmation", e)
                }
                className="mt-2 w-40"
              />
            </Form.Item>
            
            <CustomSwitch
              title="Order Now Pay Later (ONPL)"
              description="This will help to minimize order loss due to any glitch in prepaid payments. GoKwik will place the order as COD. This will also let the user to decide if they want to make a prepaid payment within a short window. "
              value={data.onpl}
              switch_key={["workflows", "onpl"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="1-click checkout"
              description="This will allow the user to quickly make a payment via last used UPI ID with just one click. "
              value={data.one_click_checkout}
              switch_key={["workflows", "one_click_checkout"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
            <CustomSwitch
              title="Address auto suggest"
              description="This feature provides real-time address auto-suggestions as users type in the address field during checkout, enhancing address accuracy and quality. "
              value={data.address_auto_suggest}
              switch_key={["workflows", "address_auto_suggest"]}
              handleConfigChange={handleConfigChange}
              isDisabled={false}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Workflows;
